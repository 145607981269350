import { useContext } from 'react';

import { UserPreferencesContext } from '../context/UserPreferencesContext';
import { CookieConsentState } from '../types/Locations';

type UseCookieConsentHook = () => [
  CookieConsentState,
  (unit: CookieConsentState) => void,
];

export const useCookieConsent: UseCookieConsentHook = () => {
  const { preferences, setCookieConsent } = useContext(UserPreferencesContext);
  return [preferences.cookieConsent, setCookieConsent];
};
