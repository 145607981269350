import { Icon } from '@design-system';
import { isBrowser } from '@utils/isBrowser';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { AccountNavigationItem } from './AccountNavigationItem';
import { DesktopNavigation } from './DesktopNavigation';
import Logo from './Logo';
import { MobileNavigation } from './MobileNavigation';

interface Props {
  isDarkMode?: boolean;
  hideMenuMain?: boolean;
  hideMenuMainMobileFooter?: boolean;
  hideMenuAccount?: boolean;
  hideBackButton?: boolean;
}

export const Navigation: React.FC<Props> = ({
  hideBackButton = true,
  hideMenuAccount = false,
  hideMenuMain = false,
  hideMenuMainMobileFooter = false,
  isDarkMode = false,
}) => {
  const router = useRouter();
  const { t } = useTranslation('navigation');

  return (
    <nav className="relative flex h-14 w-full items-center justify-center md:h-18">
      {!hideBackButton ? (
        <div className="absolute left-0 shrink-0">
          {isBrowser() && window.history.length > 1 ? (
            <button onClick={() => router.back()} type="button">
              <span className="sr-only">{t('profile.backToStore')}</span>
              <Icon name="ic_arrow_left" size="md" />
            </button>
          ) : (
            <Link href="/">
              <span className="sr-only">{t('profile.backToStore')}</span>
              <Icon name="ic_arrow_left" size="md" />
            </Link>
          )}
        </div>
      ) : null}
      {!hideMenuMain && (
        <div className="absolute left-0 shrink-0">
          <MobileNavigation
            hideFooter={hideMenuMainMobileFooter}
            isDarkMode={isDarkMode}
          />
          <DesktopNavigation />
        </div>
      )}
      <div
        className={classNames(
          { 'text-white': isDarkMode },
          'flex shrink-0 items-center justify-center',
        )}
      >
        <Logo />
      </div>
      <div className="absolute right-0 shrink-0">
        {!hideMenuAccount && (
          <div className="flex items-center justify-center space-x-1">
            <AccountNavigationItem isDarkMode={isDarkMode} />
          </div>
        )}
      </div>
    </nav>
  );
};
