import { Divider } from '@design-system';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { AuthEntryPoint } from '../../../features/auth/types';
import { pathFor } from '../../../helpers/links';
import { useHubspotChat } from '../../../hooks/useHubspotChat';
import { GeneralSubscription } from '../../GeneralSubscription';
import { FooterList } from './FooterList';
import { FooterPaymentMethods } from './FooterPaymentMethods';

export const Footer: React.FC = () => {
  const { t } = useTranslation('footer');

  useHubspotChat();

  return (
    <footer className="my-9 space-y-6 md:my-12">
      <div className="grid-section ga-y-6">
        <div className="col-span-12 flex flex-col space-y-3 xl:col-span-3">
          <div className="flex w-full max-w-lg flex-col theme-dark:text-white">
            <h3 className="font-bold">{t('signUpTitle')}</h3>
            <p>{t('signUpDescription')}</p>
          </div>
          <div className="max-w-lg">
            <GeneralSubscription
              analyticsButtonLocation="footer"
              inlineInputs
              source={AuthEntryPoint.NewsletterFooter}
              variant="secondary"
            />
          </div>
        </div>

        <div className="col-span-12 my-3 xl:hidden">
          <Divider />
        </div>

        <div className="col-span-12 grid grid-cols-12 gap-x-5 gap-y-6 xl:col-span-9">
          <div className="col-span-12 space-y-6 theme-dark:text-white sm:col-span-4">
            <FooterList
              links={[
                {
                  link: pathFor({ page: 'WORKS_INDEX' }),
                  title: t('works'),
                },
                {
                  link: pathFor({ page: 'ARTISTS_INDEX' }),
                  title: t('artists'),
                },
                {
                  link: pathFor({ page: 'INSIGHTS_INDEX' }),
                  title: t('insights'),
                },
                {
                  link: pathFor({ page: 'GLOSSARY_INDEX' }),
                  title: t('glossary'),
                },
              ]}
              title={t('discoverTitle')}
            />

            <FooterList
              links={[
                { link: pathFor({ page: 'GIFT_CARD' }), title: t('giftCards') },
              ]}
              title={t('giftsTitle')}
            />
          </div>
          <div className="col-span-12 space-y-6 theme-dark:text-white sm:col-span-4">
            <FooterList
              links={[
                { link: '/support/faq', title: t('faq') },
                { link: '/support/releases', title: t('releases') },
                { link: '/support/allocation', title: t('allocation') },
                { link: '/support/shipping', title: t('ordersShipping') },
                { link: '/support/returns', title: t('returnsRefunds') },
                {
                  link: '/support/terms-and-conditions',
                  title: t('termsConditions'),
                },
                { link: '/support/terms-of-use', title: t('termsUse') },
                {
                  link: '/support/conditions-of-sale',
                  title: t('saleConditions'),
                },
                { link: '/support/aml-policy', title: t('amlPolicy') },
                { link: '/support/privacy-policy', title: t('privacyPolicy') },
                { link: '/support/cookie-policy', title: t('cookiePolicy') },
              ]}
              title={t('customerServiceTitle')}
            />
          </div>
          <div className="col-span-12 space-y-6 theme-dark:text-white sm:col-span-4">
            <FooterList
              links={[
                { link: '/about', title: t('about') },
                { link: '/careers', title: t('careers') },
                { link: '/ambassadors', title: t('ambassadors') },
                { link: '/support/contact', title: t('contact') },
              ]}
              title={t('aboutTitle')}
            />

            <FooterList
              links={[
                {
                  isExternalLink: true,
                  link: 'https://www.instagram.com/avant.arte/',
                  title: t('primaryInstagram'),
                },
                {
                  isExternalLink: true,
                  link: 'https://www.instagram.com/avantarteinsiders/',
                  title: t('insidersInstagram'),
                },
                {
                  isExternalLink: true,
                  link: 'https://twitter.com/avant_arte',
                  title: t('twitter'),
                },
                {
                  isExternalLink: true,
                  link: 'https://discord.com/invite/8fReZrWtAP',
                  title: t('discord'),
                },
                {
                  isExternalLink: true,
                  link: 'https://www.youtube.com/c/AvantArte/featured',
                  title: t('youtube'),
                },
              ]}
              title={t('followUs')}
            />
          </div>
        </div>
      </div>

      <Divider />

      <FooterPaymentMethods />

      <div className="text-neutral-4 theme-dark:text-neutral-3">
        &#169; {new Date().getFullYear()} {t('copyright')}
      </div>
    </footer>
  );
};
