import { Icon } from '@design-system';
import Link from 'next/link';
import React, { useCallback } from 'react';

import { TrackFooterLink } from '../../../utils/analytics';

export type FooterLinkProps = {
  isExternalLink?: boolean;
  link: string;
  onClick?: () => void;
  title: string;
};

export const FooterLink: React.FC<FooterLinkProps> = ({
  isExternalLink = false,
  link,
  onClick,
  title,
}) => {
  const handleClick = useCallback(() => {
    TrackFooterLink({
      linkTitle: title,
      linkUrl: link,
      isExternal: isExternalLink,
    });
    if (typeof onClick === 'function') {
      onClick();
    }
  }, [isExternalLink, link, onClick, title]);

  return (
    <li className="flex items-start py-1">
      {isExternalLink ? (
        <a
          className="link flex items-center space-x-1 overflow-hidden"
          href={link}
          onClick={handleClick}
          rel="noreferrer"
          target="_blank"
        >
          <span className="shrink-0">
            <Icon name="ic_arrow_top_right" />
          </span>
          <span className="flex-1 truncate">{title}</span>
        </a>
      ) : (
        <Link
          className="link flex items-center space-x-1 overflow-hidden"
          href={link}
          locale={false}
          onClick={handleClick}
          prefetch={false}
        >
          <span className="flex-1 truncate">{title}</span>
        </Link>
      )}
    </li>
  );
};
