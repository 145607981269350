import { FC } from 'react';

import { FooterHeading } from './FooterHeading';
import { FooterLink, FooterLinkProps } from './FooterLink';

interface Props {
  links: FooterLinkProps[];
  title: string;
}

export const FooterList: FC<Props> = ({ links, title }) => (
  <ol>
    <li>
      <FooterHeading title={title} />
    </li>
    {links.map((link) => (
      <FooterLink key={link.title} {...link} />
    ))}
  </ol>
);
