import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

type DesktopNavigationItemProps = {
  className?: string;
  link: string;
  onClick?: (
    event?:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>,
  ) => void;
  title: string;
};

export const DesktopNavigationItem: FC<DesktopNavigationItemProps> = ({
  className,
  link,
  onClick,
  title,
}) => (
  <Link
    className={classNames(
      'link theme-dark:text-white lg:inline-block',
      className,
    )}
    href={link}
    locale={false}
    onClick={onClick}
    prefetch={false}
  >
    {title}
  </Link>
);
