import { useEffect } from 'react';

import { LayoutTheme } from '../types';

export const useTheme = (theme: LayoutTheme) => {
  useEffect(() => {
    document.documentElement.classList.forEach((className) => {
      if (className.startsWith('theme-')) {
        document.documentElement.classList.remove(className);
      }
    });

    if (theme === 'dark') {
      document.documentElement.classList.add('theme-dark');
    } else if (theme === 'dark-grey') {
      document.documentElement.classList.add('theme-dark-grey');
    } else if (theme === 'light-grey') {
      document.documentElement.classList.add('theme-light-grey');
    } else {
      document.documentElement.classList.add('theme-light');
    }
  }, [theme]);
};
