import { useState } from 'react';

import { csrClient } from '../apollo/api/avantArteClient';
import { GENERAL_SUBSCRIPTION } from '../constants/subscriptions';
import { useSubscriptionsManager } from '../features/auth/hooks/useSubscriptionsManager';
import { AuthEntryPoint } from '../features/auth/types';
import { useAuth } from '../providers';
import {
  GeneratedSubscriptionType,
  IsSubscribedDocument,
} from '../types/generated';
import { ButtonLocation } from '../types/Subscriptions';
import { useAnalyticsEmitter } from '../utils/analytics/emitter';
import { useIsSubscribed } from './subscription';

const GENERAL_SUBS = {
  objectId: GENERAL_SUBSCRIPTION,
  type: GeneratedSubscriptionType.General,
};

const GENERAL_REF = {
  ...GENERAL_SUBS,
  name: GENERAL_SUBSCRIPTION,
  ref: '',
};

type Options = {
  buttonLocation: ButtonLocation;
  source: AuthEntryPoint;
};

export const useGeneralSubscription = ({ buttonLocation, source }: Options) => {
  const [showSubscription, setShowSubscription] = useState(false);
  const [emitter] = useAnalyticsEmitter();
  const { loading, subscribe } = useSubscriptionsManager();
  const { signupAndSubscribe, user } = useAuth();

  const { isSubscribedAll } = useIsSubscribed([GENERAL_SUBS]);

  const analytics = {
    'button_location': buttonLocation,
    source,
  };

  return {
    subscribed: showSubscription && isSubscribedAll,
    loading: showSubscription && loading,
    start: async (email?: string, captchaToken?: string) => {
      setShowSubscription(true);

      if (user) {
        emitter('subscribe', 'submit', analytics, {
          omitObject: true,
        });

        const success = await subscribe([GENERAL_REF]);
        if (success) {
          csrClient.refetchQueries({ include: [IsSubscribedDocument] });
          emitter('subscribe', 'success', analytics, {
            omitObject: true,
          });
        }
        return { success: true };
      }

      if (email) {
        const response = await signupAndSubscribe({
          email,
          subscriptions: [GENERAL_REF],
          silenceLoginCodeEmail: true,
          captchaToken,
        });

        if (response.data?.signupAndSubscribe) {
          emitter('subscribe', 'success', analytics, {
            omitObject: true,
          });

          return { success: true };
        }
      }

      return { success: false };
    },
  };
};
