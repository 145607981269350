import { Button } from '@design-system';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';

import { useCookieConsent } from '../../../hooks/useCookieConsent';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import PageSection from '../../Layout/PageSection';

const CookieConsent: React.FC = () => {
  const { t } = useTranslation('common');
  const [_, setCookieConsent] = useCookieConsent();
  const isMobile = useMediaQuery('sm');

  const handleConfirmation = () => {
    setCookieConsent('GRANTED');
  };

  return (
    <div className="theme-light bg-neutral-2 py-3">
      <PageSection>
        <div
          className="flex flex-col items-center justify-center gap-3 sm:flex-row"
          data-testid="cookie-consent"
        >
          <div className="w-full max-w-sm flex-1 text-center sm:max-w-full sm:text-left">
            <Trans i18nKey="cookieConsentMessage" t={t}>
              We use cookies and may process personal data. To find out more,
              check our&nbsp;
              <a
                className="hyperlink"
                href="/support/cookie-policy"
                target="_blank"
              >
                cookie policy
              </a>
            </Trans>
          </div>
          <div className="shrink-0">
            <Button
              fullWidth={isMobile}
              label={t('cookieConsentConfirmAction')}
              onClick={handleConfirmation}
              size="md"
              variant="primary"
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
};

export default CookieConsent;
