export const useHubspotChat = () => {
  function openHandler() {
    const status = window.HubSpotConversations?.widget.status();

    // Check if the widget has loaded
    if (!status?.loaded) window.HubSpotConversations?.widget.refresh();

    // Open the widget
    window.HubSpotConversations?.widget.open();
  }

  function hideHubSpot() {
    window.HubSpotConversations?.widget.remove();
  }

  function showHubspot() {
    window.HubSpotConversations?.widget.load();
  }

  return {
    openHandler,
    hideHubSpot,
    showHubspot,
  };
};
