import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import {
  DEFAULT_PAYMENT_ORDER,
  IDEAL_COUNTRIES,
  KLARNA_COUNTRIES,
  PAYMENT_METHOD_IDEAL,
  PAYMENT_METHOD_KLARNA,
} from '../../../constants/payment';
import { useCountryCode } from '../../../hooks/useCountryCode';
import { Image } from '../../Image';

const FooterLocale = dynamic(() => import('./FooterLocale'), { ssr: false });

export const FooterPaymentMethods: FC = () => {
  const { t } = useTranslation('footer');

  const [countryCode] = useCountryCode();
  const [paymentOrder, setPaymentOrder] = useState(DEFAULT_PAYMENT_ORDER);

  const excludePaymentMethods = (methods: Array<string>) => {
    const updatedPaymentOrder = DEFAULT_PAYMENT_ORDER.filter(
      (payment) => !methods.includes(payment),
    );
    return updatedPaymentOrder;
  };

  useEffect(() => {
    const excludedPaymentMethods: Array<string> = [];
    if (!KLARNA_COUNTRIES.includes(countryCode)) {
      excludedPaymentMethods.push(PAYMENT_METHOD_KLARNA);
    }
    if (!IDEAL_COUNTRIES.includes(countryCode)) {
      excludedPaymentMethods.push(PAYMENT_METHOD_IDEAL);
    }

    const order = excludePaymentMethods(excludedPaymentMethods);
    setPaymentOrder(order);
  }, [countryCode]);

  return (
    <div className="flex flex-col items-start justify-between gap-y-6 theme-dark:text-white md:flex-row md:items-center md:gap-x-3 md:gap-y-0">
      <div className="flex w-full flex-1 flex-wrap gap-2">
        {paymentOrder.map((payment) => (
          <div
            key={payment}
            className="w-14 overflow-hidden rounded-sm border border-divider/20"
          >
            <Image
              alt={payment}
              size={{ sm: { ratio: 'intrinsic', span: 12 } }}
              src={t(`payment.${payment}`)}
              unoptimized
              width={64}
            />
          </div>
        ))}
      </div>

      <div className="shrink-0">
        <FooterLocale />
      </div>
    </div>
  );
};
