import Head from 'next/head';
import React from 'react';

const ogImageUrl = new URL(process.env.NEXT_PUBLIC_HOSTNAME!);
ogImageUrl.pathname = '/images/social.png';

const DEFAULT_IMAGE_URL = ogImageUrl.toString();
const DEFAULT_TYPE = 'website';

type Props = {
  title: string;
  description: string;
  url: string;
  imageUrl?: string;
  type?: 'article' | 'website';
  hostname?: string;
};

const SocialMetadata: React.FC<Props> = ({
  description,
  hostname,
  imageUrl = DEFAULT_IMAGE_URL,
  title,
  type = DEFAULT_TYPE,
  url,
}) => {
  return (
    <Head>
      <meta content={title} property="og:title" />
      <meta content={description} property="og:description" />
      <meta content={type} property="og:type" />
      <meta content={url} property="og:url" />
      <meta content={imageUrl} property="og:image" />
      <meta content="1200" property="og:image:width" />
      <meta content="630" property="og:image:height" />

      <meta content={hostname || 'avantarte.com'} property="twitter:domain" />
      <meta content={url} property="twitter:url" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={title} name="twitter:title" />
      <meta content={description} name="twitter:description" />
      <meta content={imageUrl} name="twitter:image" />

      <meta
        content="38k1l0uq3tn9o3yld2lihc79kuvybf"
        name="facebook-domain-verification"
      />

      <meta
        content="tBwBcGldly0LIou-4SQNwINDwbeiFTyxgy3-i8NtXmU"
        name="google-site-verification"
      />
    </Head>
  );
};

export default SocialMetadata;
