import Link from 'next/link';
import React, { FC, useCallback } from 'react';

import { useNavigation } from './NavigationContext';

type Props = {
  title: string;
  link: string;
  onClick?: (event?: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const MobileNavigationItem: FC<Props> = ({
  link,
  onClick = () => undefined,
  title,
}) => {
  const { setMobileNavOpen } = useNavigation();

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick();
    }
    setMobileNavOpen(false);
  }, [onClick, setMobileNavOpen]);

  return (
    <li>
      <Link
        className="flex items-center text-lg theme-dark:text-white"
        href={link}
        locale={false}
        onClick={handleClick}
        prefetch={false}
      >
        {title}
      </Link>
    </li>
  );
};
