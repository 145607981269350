export const PAYMENT_METHOD_VISA = 'visa';
export const PAYMENT_METHOD_MASTER_CARD = 'masterCard';
export const PAYMENT_METHOD_AMERICAN_EXPRESS = 'americanExpress';
export const PAYMENT_METHOD_PAY_PAL = 'payPal';
export const PAYMENT_APPLE_PAY = 'applePay';
export const PAYMENT_GOOGLE_PAY = 'googlePay';
export const PAYMENT_METHOD_IDEAL = 'iDeal';
export const PAYMENT_METHOD_WE_CHAT = 'weChat';
export const PAYMENT_METHOD_KLARNA = 'klarna';
export const PAYMENT_METHOD_CRYPTO_COM = 'cryptoCom';

export const DEFAULT_PAYMENT_ORDER = [
  PAYMENT_METHOD_VISA,
  PAYMENT_METHOD_MASTER_CARD,
  PAYMENT_METHOD_AMERICAN_EXPRESS,
  PAYMENT_METHOD_PAY_PAL,
  PAYMENT_APPLE_PAY,
  PAYMENT_GOOGLE_PAY,
  PAYMENT_METHOD_IDEAL,
  PAYMENT_METHOD_WE_CHAT,
  PAYMENT_METHOD_KLARNA,
  PAYMENT_METHOD_CRYPTO_COM,
];

export const IDEAL_COUNTRIES = [
  'NL', // Netherlands
];

export const KLARNA_MAX_IN_EUROS = 5000;
export const KLARNA_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'DE', // Germany
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'IE', // Ireland
  'IT', // Italy
  'NL', // Netherlands
  'PT', // Portugal
  'GB', // United Kingdom
];
