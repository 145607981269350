import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { toCanonicalUrl } from '../../helpers/links';
import { useCookieConsent } from '../../hooks/useCookieConsent';
import CookieConsent from '../Containers/CookieConsent';
import SocialMetadata from '../SocialMetadata';
import { Footer } from './Footer';
import Head from './Head';
import { Navigation } from './Navigation';
import { useNavigation } from './Navigation/NavigationContext';
import PageSection from './PageSection';
import { useTheme } from './Theme/useTheme';
import { LayoutTheme } from './types';

type Props = {
  children: React.ReactNode;
  locale?: string;
  locales?: Array<string>;
  title?: string;
  description?: string;
  ogType?: 'article' | 'website';
  ogImageUrl?: string;
  canonicalUrl?: string;
  showHeader?: boolean;
  theme?: LayoutTheme;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideMenuMain?: boolean;
  hideMenuMainMobileFooter?: boolean;
  hideMenuAccount?: boolean;
  hideBackButton?: boolean;
};

const Layout = ({
  canonicalUrl,
  children,
  description = '',
  hideBackButton = true,
  hideFooter = false,
  hideHeader = false,
  hideMenuAccount = false,
  hideMenuMain = false,
  hideMenuMainMobileFooter = false,
  ogImageUrl,
  ogType,
  theme = 'light',
  title = '',
}: Props) => {
  useTheme(theme);
  const { asPath, locale } = useRouter();
  const fallbackCanonicalUrl = toCanonicalUrl({
    path: asPath,
    locale,
    hostname: process.env.NEXT_PUBLIC_HOSTNAME!,
  });

  const [cookieConsent] = useCookieConsent();
  const { mobileNavOpen } = useNavigation();

  useEffect(() => {
    const visible = !mobileNavOpen && cookieConsent !== 'GRANTED';

    if (visible) {
      document.body.classList.add('cookie-banner-visible');
    } else {
      document.body.classList.remove('cookie-banner-visible');
    }
  }, [cookieConsent, mobileNavOpen]);

  const isDarkTheme = theme === 'dark';

  return (
    <div className="flex min-h-screen flex-col">
      <Head
        description={description}
        theme={theme}
        title={title}
        urls={{ canonical: canonicalUrl || fallbackCanonicalUrl }}
      />

      <SocialMetadata
        description={description}
        imageUrl={ogImageUrl}
        title={title}
        type={ogType}
        url={canonicalUrl || fallbackCanonicalUrl}
      />
      {!hideHeader && (
        <div
          className={classNames(
            'fixed left-0 right-0 top-0 z-90 w-full border-b border-divider/20 bg-white',
            'theme-dark:border-divider/20 theme-dark:bg-neutral-6',
          )}
        >
          <PageSection>
            <Navigation
              hideBackButton={hideBackButton}
              hideMenuAccount={hideMenuAccount}
              hideMenuMain={hideMenuMain}
              hideMenuMainMobileFooter={hideMenuMainMobileFooter}
              isDarkMode={theme === 'dark'}
            />
          </PageSection>
        </div>
      )}
      <div
        className={classNames('flex min-h-160 flex-auto flex-col', {
          'mt-14 md:mt-18': !hideHeader,
        })}
      >
        {children}
      </div>
      {!hideFooter && (
        <div
          className={classNames(
            'w-full border-t border-divider/20',
            isDarkTheme ? 'bg-neutral-6' : 'bg-white',
          )}
        >
          <PageSection>
            <Footer />
          </PageSection>
        </div>
      )}
      <div className="cookie-banner-container z-90">
        <CookieConsent />
      </div>
    </div>
  );
};

export default Layout;
