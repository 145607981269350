import { Alert, Button } from '@design-system';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

import { useGeneralSubscription } from '../../hooks/useGeneralSubscription';
import { TrackEmailSubscription } from '../../utils/analytics';
import { GeneralSubscriptionProps } from './types';

export const SignedInSubscription: React.FC<GeneralSubscriptionProps> = ({
  analyticsButtonLocation,
  source,
  variant = 'primary',
}) => {
  const { t } = useTranslation(['footer', 'subscriptions']);

  const { loading, start, subscribed } = useGeneralSubscription({
    buttonLocation: analyticsButtonLocation,
    source,
  });

  useEffect(() => {
    if (!subscribed) return;

    TrackEmailSubscription({
      mode: 'GENERAL',
      type: 'success',
      buttonLocation: analyticsButtonLocation,
      generalMailList: true,
      trackEvent: false,
      subscribed: true,
      hasJustSignedUp: true,
    });
  }, [analyticsButtonLocation, subscribed]);

  if (subscribed) {
    return (
      <Alert status="success">
        {t('subscriptions:generalForm.successAlertBrief')}
      </Alert>
    );
  }

  return (
    <Button
      disabled={subscribed || loading}
      icon={subscribed ? 'ic_check' : undefined}
      label={subscribed ? t('signUpSuccess') : t('signUpButton')}
      loading={loading}
      onClick={() => void start()}
      variant={subscribed ? 'positive' : variant}
    />
  );
};
