import { AvantArteLogo } from '@design-system';
import classNames from 'classnames';
import Link from 'next/link';

import { HEADER_TITLE } from '../../../constants';
import { useViewportScroll } from '../../../hooks/useViewportScroll';
import { TrackNavbarLinkClicked } from '../../../utils/analytics';

const Logo = () => {
  const { scrollY } = useViewportScroll();

  return (
    <Link
      aria-label={HEADER_TITLE}
      className="overflow-hidden"
      href="/"
      locale={false}
      onClick={() =>
        TrackNavbarLinkClicked({
          context: 'logo',
          title: '(logo)',
        })
      }
      prefetch={false}
    >
      <div
        className={classNames(
          'h-14 -translate-y-14 transition-transform duration-300 md:h-18 md:-translate-y-18',
          { 'lg:translate-y-0': scrollY === 0 },
        )}
      >
        <div className="flex flex-col justify-center">
          <div className="flex h-14 items-center justify-center md:h-18">
            <AvantArteLogo variant="wordmark" />
          </div>
          <div className="flex h-14 items-center justify-center md:h-18">
            <AvantArteLogo variant="icon" />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Logo;
